<template>
  <div class="dashboard-container">
    <Sidenav />
    <main>
      <Header />
      <div class="main-container">
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
const Sidenav = () => import('@/components/Sidenav.component')
const Header = () => import('@/components/Header.component')

export default {
  components: {
    Sidenav,
    Header,
  },
}
</script>

<style lang="scss" scoped>
.dashboard-container {
  min-height: 100vh;
  display: flex;
  width: 100%;
}

.main-container {
  padding: 3%;
  padding-top: 0;
  display: block;
}

main {
  width: 100%;
}
</style>
